// Override default variables
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
// Import Bootstrap and its default variables

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700;800;900&display=swap");

$primary: #7265e3;
$secondary: #6e7b98;
$danger: #df0404;
$body-bg: #f0f4ff;
$info: #f0f4ff;
$success: #f6fafd;
$light: #ffffff;

$border-radius: 1.125rem;
$nav-pills-border-radius: 0.375rem;
$btn-border-radius: 0.375rem;

$font-family-base: "Lexent", sans-serif;

//$modal-backdrop-bg: #6C6C6C;
//$modal-backdrop-opacity: 0.5;
$modal-md: 500px;
$modal-content-border-radius: 1.125rem;

$enable-negative-margins: true;

$input-focus-border-color: red;

//Define global styles
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  resize: none !important;
}

.table-wrapper {
  // min-height: 60vh;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  td {
    font-size: 0.8rem;
    font-weight: 600;
  }
  th {
    padding-top: 6px;
    border-bottom: none !important;
  }
  th:first-of-type {
    // border-top-left-radius: 12px;
  }
  th:last-of-type {
    // border-top-right-radius: 12px;
  }
}

.headersFix {
  th:first-of-type {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
  th:last-of-type {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
}
#sidebar {
  .MuiPaper-root-MuiDrawer-paper {
    background-color: #fbfafc !important;
    height: 50vh !important;
    margin-left: 1em;
    margin-top: 1em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  }
}

.custom-filepond-container {
  background: url("./assets/imgDrop.png") center/contain no-repeat !important;
  background-size: cover;
  cursor: pointer;
  width: 300px !important; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */
  border: 1px solid #ccc; /* Optional: Add a border for visual separation */
}
.custom-filepond-container .filepond--label-action {
  display: none; /* Hide the "Suba un archivo" text */
}
.swal2-spinner {
  margin: 0 auto;
  width: 40px;
  overflow: hidden;
  height: 40px;
  position: relative;
  box-sizing: border-box;
  border: 4px solid #f3f3f3;
  border-left: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
