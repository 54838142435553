@import "./../../custom.scss";

a.active {
  color: white !important;
}

.inactive-icon {
  fill: $secondary;
}

.force-white button {
  background-color: #fff !important;
  border-color: #fff;
}
