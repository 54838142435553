// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/bootstrap";

html {
  overflow-y: auto;
  overflow-x: hidden;
}

body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

body::-webkit-scrollbar-thumb {
  background-color: #cf8b00;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #cf8b00;
}

html {
  scroll-behavior: smooth;
}
div:where(.swal2-container) {
  display: grid;
  position: fixed;
  z-index: 10600 !important;
  inset: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(
      min-content,
      auto
    );
  height: 100%;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}
